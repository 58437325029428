import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"


// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      home page
    </Layout>
  )
}

export default IndexPage
